import React from "react";
import firebase from "gatsby-plugin-firebase";
import "firebase/storage";
import styled from "styled-components";
import { useQuery } from "react-query";

const storage = firebase.storage();
export const imageRef = storage.ref().child("public/website/images");

export const FirebaseImage = ({
  image,
  alt,
  mobileRes = "100vw",
  desktopRes = "50vw",
  style = {},
}: {
  image: string;
  alt?: string;
  mobileRes?: string;
  desktopRes?: string;
  style?: React.CSSProperties;
}) => {
  const { isLoading, error, data } = useQuery(
    image,
    async () => {
      const sizes = ["300x300", "600x600", "900x900", "1200x1200"];

      const [fileName, extension] = image.split(".");

      const files = sizes.map((size) => {
        return `${fileName}_${size}.${extension}`;
      });

      const srcsetArray = await Promise.all(
        files.map(async (file, index) => {
          const src = await imageRef.child(file).getDownloadURL();
          const width = sizes[index].split("x")[0];

          return `${src} ${width}w`;
        })
      );

      const srcset = srcsetArray.join(", ");

      return srcset;
    },
    { staleTime: 24 * 60 * 60 * 1000 }
  );

  if (isLoading) return null;

  if (error) {
    console.error(error);
    return null;
  }

  return (
    <Image
      srcSet={data}
      sizes={`(max-width: 600px) ${mobileRes}, (min-width: 600px) ${desktopRes}`}
      alt={alt}
      style={style}
    />
  );
};

const Image = styled.img`
  width: 100%;
  height: 100%;

  object-fit: cover;
`;
